import React, {useEffect, useState} from "react";
import Alert from "@mui/material/Alert";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import TableBody from "@mui/material/TableBody";
import Link from "@mui/material/Link";
import CircularProgress from '@mui/material/CircularProgress';

import apiCallsService from '../../services/apiCalls.service';

export const TableWidget = ({aggregation, summaryType, periodId, limit = 10, country = null, query = null}) => {
	const [loading, setLoading] = useState(false);
	const [errorText, setErrorText] = useState("");

	const [tableData, setTableData] = useState([]);



	const fetchData = async () => {
		setLoading(true);
		setErrorText('');

		const data = await apiCallsService.getKeywordsSummary(aggregation, summaryType, periodId, {limit, country, ...query});

		if (data.error) {
			setErrorText('Failed to obtain the data');
			return;
		}

		setTableData(data);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [aggregation, summaryType, periodId, limit, country, query]);

	const ratioToPercentage = ratio => `${ratio > 0 ? '+' : ''}${Math.round(ratio * 100) }%`;
	const ratioToPercentageWithoutSign = ratio => `${Math.round(ratio * 100) }%`;

	if (errorText) {
		return (
			<Alert severity="error" style={{ marginBottom: "10px" }}>
				{errorText}
			</Alert>
		)
	};

	const cellStyles = { fontSize: '12px', padding: '0.5em' };
	const linkStyles = {color: 'rgba(0, 0, 0, 0.87)'};

	const valueCellName = aggregation === 'keywords' ? 'Keyword' : 'Offer';
	const getValueCellName = record => aggregation === 'keywords' ? record.keyword : record.offer;

	const getLinkToAnalyseOffer = (affilateId, offerId, country, periodId) => {
		const linkMap = {
			tonic: `/analyse-tonic-offers?`,
			crossroads: `/analyse-crossroads-offers?`
		}

		const queryParam = `country=${country}&offer=${offerId}&periodId=${periodId || 'All'}`;

		return linkMap[affilateId] + queryParam;
	}

	if (['compare_to_offer_revenue', 'compare_to_offer_revenue_negative'].includes(summaryType)) {

		const moreIsBetter = summaryType === 'compare_to_offer_revenue';

		const styleRecord = moreIsBetter
			? record => ({backgroundColor: `rgba(129, 209, 121, ${1 - record.clicks_ratio * 100/20})`})
			: record => ({backgroundColor: `rgba(255, 0, 0, ${record.clicks_ratio})`});

		return (
			<>
				<p style={{marginBottom: '1em', fontSize: '0.8em'}}>The color intensity indicates the size of the traffic share this keyword accounts for.</p>
				{loading ? (
					<CircularProgress size="4rem" sx={{ ml: '45%'}}/>
				) : ( 
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={cellStyles}>{valueCellName}</TableCell>
									<TableCell style={cellStyles}>Offer</TableCell>
									<TableCell>Affiliate</TableCell>
									<TableCell style={cellStyles}>
										Conversions
										<Tooltip title="Traffic share represents the amount of traffic a specific keyword receives, out of the total traffic generated for an offer (respectively)"><span> (traffic share)</span></Tooltip>
									</TableCell>
									<TableCell style={cellStyles}>Volume</TableCell>
									<TableCell style={cellStyles}>Avg RPC (vs Offer Avg)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.length === 0 ? (
									<TableRow> 
										<TableCell colSpan={6} sx={{ textAlign: 'center', padding: '20px' }}> 
											No data
										</TableCell>
									</TableRow>
								) : ( 
									tableData.map((record, i) => (
										<TableRow key={i} sx={styleRecord(record)}>
											<TableCell style={cellStyles}>{getValueCellName(record)}</TableCell>
											<TableCell style={cellStyles}>
												<Link href={getLinkToAnalyseOffer(record.affiliateid, record.offerId, record.country, periodId)} target="_blank" style={linkStyles}>
													{record.offer}, {record.country}
												</Link>
											</TableCell>
											<TableCell style={cellStyles}>{record.affiliateid}</TableCell>
											<TableCell style={cellStyles}>{record.clicks_current} ({ratioToPercentageWithoutSign(record.clicks_ratio)})</TableCell>
											<TableCell style={cellStyles}>${record.avg_offer_revenue}</TableCell>
											<TableCell style={cellStyles}>${record.avg_revenue_current}  ({ratioToPercentage(record.avg_revenue_ratio)})</TableCell>
										</TableRow>))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</>
		);
	}


	if (['weekly_seasonality'].includes(summaryType)) {
		const styleRecord = record => {
			return record.clicks_ratio > 0
				? {}
				: {backgroundColor: `rgba(129, 209, 121, ${ record.clicks_ratio * -1  })`}
		};

		return (
			<>
				<p style={{marginBottom: '1em', fontSize: '0.8em'}}>
					The color intensity indicates the size of the traffic share this keyword accounts for.
					<br />Green indicates that the seasonality of the offer is used inefficiently. More traffic on this day should yield better results.
				</p>
				{loading ? (
					<CircularProgress size="4rem" sx={{ ml: '45%'}}/>
				) : ( 
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell style={cellStyles}>{valueCellName}</TableCell>
									<TableCell style={cellStyles}>Affiliate</TableCell>
									<TableCell style={cellStyles}>
										Conversions
										<Tooltip title="Traffic share represents the amount of traffic a specific keyword receives, out of the total traffic generated for an offer (respectively)"><span> (traffic share)</span></Tooltip>
									</TableCell>
									<TableCell style={cellStyles}>Avg Conversions per day</TableCell>
									<TableCell style={cellStyles}>Offer Volume</TableCell>
									<TableCell style={cellStyles}>Offer Avg</TableCell>
									<TableCell style={cellStyles}>Avg RPC for day of week (vs Offer Avg)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.map((record, i) => <TableRow key={i} sx={styleRecord(record)}>
									<TableCell style={cellStyles}>
										<Link href={getLinkToAnalyseOffer(record.affiliateid, record.offerId, record.country)} target="_blank" style={linkStyles}>
											{getValueCellName(record)}, {record.country}
										</Link>
									</TableCell>
									<TableCell style={cellStyles}>{record.affiliateid}</TableCell>
									<TableCell style={cellStyles}>{record.clicks_current} ({ratioToPercentageWithoutSign(record.clicks_ratio)})</TableCell>
									<TableCell style={cellStyles}>{Math.round((record.clicks_monday + record.clicks_tuesday + record.clicks_wednesday + record.clicks_thursday + record.clicks_friday + record.clicks_saturday + record.clicks_sunday) / 7)}</TableCell>

									<TableCell style={cellStyles}>${Math.round(record.avg_offer_revenue * record.clicks_offer)}</TableCell>
									<TableCell style={cellStyles}>${record.avg_offer_revenue}</TableCell>
									<TableCell style={cellStyles}>${record.avg_revenue_current}  ({ratioToPercentage(record.avg_revenue_ratio)})</TableCell>
								</TableRow>)}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</>
		);
	}

	return (
		<>
			{loading ? (
				<CircularProgress size="4rem" sx={{ ml: '45%'}}/>
			) : ( 
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell style={cellStyles}>{valueCellName}</TableCell>
								<TableCell style={cellStyles}>Conversions</TableCell>
								<TableCell style={cellStyles}>Volume</TableCell>
								<TableCell style={cellStyles}>RPC</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData.map((record, i) => <TableRow key={i}>
								<TableCell style={cellStyles}>
									{ aggregation === 'keywords'
										? <>
											{record.keyword}
											<br/>
											<Link href={getLinkToAnalyseOffer(record.affiliateid, record.offerId, record.country, periodId)} target="_blank" style={linkStyles}>
												{record.offer}, {record.country}
											</Link>
										</>
										: <Link href={getLinkToAnalyseOffer(record.affiliateid, record.offerId, record.country, periodId)} target="_blank" style={linkStyles}>
											{record.offer}, {record.country}
										</Link>
									}
								</TableCell>
								<TableCell style={cellStyles}>{record.clicks_current} ({ratioToPercentage(record.clicks_ratio)})</TableCell>
								<TableCell style={cellStyles}>${Math.round(record.clicks_current * record.avg_revenue_current)} ({ratioToPercentage(record.volume_ratio)})</TableCell>
								<TableCell style={cellStyles}>${record.avg_revenue_current}  ({ratioToPercentage(record.avg_revenue_ratio)})</TableCell>
							</TableRow>)}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	)
};
