import React, { useEffect, useState } from 'react';

import { Title } from '../../components/Title';
import ExpandMoreLessButton from '../../components/ExpandMoreLessButton';
import apiCallsService from '../../services/apiCalls.service';
import TablePage from '../../styledComponents/pages/TablePage';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";

const CURRENCY = 'PLN';

export const AdAccountsSpendLimit = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [searchByNameQuery, setSearchByNameQuery] = useState('');

	const [sortingBy, setSortingBy] = useState('remainSpend');
	const [sortingDirection, setSortingDirection] = useState(false);

	const fetchAdAccountsMetadata = async () => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getAdAccountsMetadata();

			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);

				return;
			}

			const exchangeRates = localStorage.getItem('exchangeRates');

			if (exchangeRates) {
				const parsedRates = JSON.parse(exchangeRates);
				const rate = parsedRates[CURRENCY] || 1;

				const updatedData = response.map(record => ({
					...record,
					remainSpend: record.currency === CURRENCY ? record.remainSpend / rate : record.remainSpend
				}));

				setTableData(updatedData);
			} else {
				setTableData(response || []);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let result = [...tableData];

		if (sortingBy) {
			if (sortingDirection) {
				result = result.sort((a, b) => a[sortingBy] - b[sortingBy])
			}
			else {
				result = result.sort((a, b) => b[sortingBy] - a[sortingBy])
			}
		}

		if (searchByNameQuery) {
			result = result.filter(record => 
				record.originalName.toLowerCase().includes(searchByNameQuery.toLowerCase())
			);
		}

		setFilteredTableData(result);

	}, [tableData, sortingDirection, sortingBy, searchByNameQuery]);

	useEffect(() => {
		fetchAdAccountsMetadata();
	}, []);

	const handleSortingBy = attribute => {
		if (attribute === sortingBy) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
	};


	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});

	return (
		<>
			<Title title="Ad Accounts Spend Limit" />

			<TablePage>
				{loading ? (
						<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
				) : error ? (
						<Alert severity="error">Error: {error}</Alert>
				) : ( <TableContainer>
					<Box
						sx={{
							backgroundColor: '#f0f7fb',
							borderLeft: 'solid 4px #3498db',
							lineHeight: '18px',
							overflow: 'hidden',
							padding: '12px',
							textAlign: 'left',
							marginBottom: '12px'
						}}
					>	
						<Typography color="text.secondary" variant="body2">
							<b>Remain Spend</b> - how much is left before the account exceeds the limit
						</Typography>
					</Box>

					<TextField
							sx={{ marginBottom: '20px' }}
							label="Search by name"
							variant="outlined"
							fullWidth
							margin="normal"
							value={searchByNameQuery}
							onChange={(e) => setSearchByNameQuery(e.target.value)}
						/>

					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Account</TableCell>
								<TableCell style={stylesTableHead('remainSpend')} onClick={() => handleSortingBy('remainSpend')}>Remain Spend<ExpandMoreLessButton enabled={sortingBy === 'remainSpend'} sortingDirection={sortingDirection}/></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredTableData.map((record, index) => (
									<TableRow key={index}>
										<TableCell>{record.originalName}</TableCell>
										<TableCell>
											{(record.remainSpend / 100).toFixed(2)} $</TableCell>
									</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>)}
			</TablePage>
		</>
	);
};
