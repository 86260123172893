import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import { Login } from '../pages/Login';
import { NewCampaign } from '../pages/NewCampaign';
import { AddPixel } from '../pages/AddPixel';
import { CampaignOverview } from '../pages/CampaignOverview';
import { MyProfile } from '../pages/MyProfile';
import { PixelsOverview } from '../pages/PixelsOverview';
import { DownloadAttachments } from '../pages/DownloadAttachments';
import { AnalyseTonicOffers } from '../pages/AnalyseTonicOffers';
import { AnalyseCrossroadsOffers } from '../pages/AnalyseCrossroadsOffers';
import { KeywordsDashboard } from '../pages/KeywordsDashboard';
import { CompareOffers } from '../pages/CompareOffers';
import { AnalyseOffersList } from '../pages/AnalyseOffersList';
import { AnalyseKeywordsList } from '../pages/AnalyseKeywordsList';
import { AdAccountsSpendLimit } from '../pages/AdAccounts/AdAccountsSpendLimit';
import { AdAccountsMonthlySpend } from '../pages/AdAccounts/AdAccountsMonthlySpend';
import { AdAccountsCreativesCTR } from '../pages/AdAccounts/AdAccountsCreativesCTR';

export const Road = () => {
	const REACT_APP_DESIGNER_APP_URL = process.env.REACT_APP_DESIGNER_APP_URL.replace('__HOST__', window.location.hostname);

	return (
		<Routes>
			<Route path='/' element={<PrivateRoute roles={['lead', 'buyer', 'helper']} root={true} component={NewCampaign} />} />
			<Route path='/login' element={<Login />} />
			<Route path='/designer_login' element={<Login stateless={true} redirect={REACT_APP_DESIGNER_APP_URL}/>} />
			<Route path='/campaign-overview' element={<PrivateRoute roles={['lead', 'buyer', 'helper']} component={CampaignOverview} />} />
			<Route path='/my-profile' element={<PrivateRoute roles={['lead', 'buyer', 'helper']} component={MyProfile} />} />
			<Route path='/pixels-overview' element={<PrivateRoute roles={['lead', 'buyer', 'helper']} component={PixelsOverview} />} />
			<Route path='/download-attachments' element={<PrivateRoute roles={['lead', 'buyer', 'helper']} component={DownloadAttachments} />} />
			<Route path='/add-pixel' element={<PrivateRoute roles={['lead', 'buyer']} component={AddPixel} />} />
			<Route path='/analyse-tonic-offers' element={<PrivateRoute roles={['lead', 'buyer']} component={AnalyseTonicOffers} />} />
			<Route path='/analyse-crossroads-offers' element={<PrivateRoute roles={['lead', 'buyer']} component={AnalyseCrossroadsOffers} />} />
			<Route path='/keywords-dashboard' element={<PrivateRoute roles={['lead', 'buyer']} component={KeywordsDashboard} />} />
			<Route path='/compare-offers' element={<PrivateRoute roles={['lead', 'buyer']} component={CompareOffers} />} />
			<Route path='/analyse-offers-list' element={<PrivateRoute roles={['lead', 'buyer']} component={AnalyseOffersList} />} />
			<Route path='/analyse-keywords-list' element={<PrivateRoute roles={['lead', 'buyer']} component={AnalyseKeywordsList} />}  />
			<Route path='/ad-accounts-spend-limit' element={<PrivateRoute roles={['lead', 'buyer']} component={AdAccountsSpendLimit} />}  />
			<Route path='/ad-accounts-monthly-spend' element={<PrivateRoute roles={['lead']} component={AdAccountsMonthlySpend} />}  />
			<Route path='/ad-accounts-creatives-ctr' element={<PrivateRoute roles={['lead']} component={AdAccountsCreativesCTR} />}  />

			<Route path='*' element={<Navigate to='/' replace />} />
		</Routes>
	);
};
