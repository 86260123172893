import React, { useEffect, useState } from 'react';

import { Title } from '../../components/Title';
import ExpandMoreLessButton from '../../components/ExpandMoreLessButton';
import apiCallsService from '../../services/apiCalls.service';
import TablePage from '../../styledComponents/pages/TablePage';

import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";

const PLN_CURRENCY_CODE = 'PLN';
const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const AdAccountsMonthlySpend = () => {
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');

const [tableData, setTableData] = useState([]);
const [filteredTableData, setFilteredTableData] = useState([]);

const [searchByNameQuery, setSearchByNameQuery] = useState('');

const [sortingByMonth, setSortingByMonth] = useState(null);
const [sortingDirection, setSortingDirection] = useState(false);

const fetchAdAccountsMetadata = async () => {
	setLoading(true);
	setError('');

	try {
		const response = await apiCallsService.getMonthlySpendByAdAccount();

	if (response.error) {
		setError(response.error);
		setTableData([]);
		setFilteredTableData([]);

		return;
	}

		setTableData(response || []);
	} catch (err) {
		setError('Failed to fetch data');
		setTableData([]);
		setFilteredTableData([]);
	} finally {
		setLoading(false);
	}
};

useEffect(() => {
	let result = [...tableData];

	if (sortingByMonth) {
		result = result.sort((a, b) => {
			const aMonthData = a.monthlySpend.find(ms => ms.month === sortingByMonth);
			const bMonthData = b.monthlySpend.find(ms => ms.month === sortingByMonth);

			const aValue = aMonthData?.totalSpend || 0;
			const bValue = bMonthData?.totalSpend || 0;

			return sortingDirection ? aValue - bValue : bValue - aValue; 
		});
	}

	if (searchByNameQuery) {
		result = result.filter(record =>
			record.adAccountName.toLowerCase().includes(searchByNameQuery.toLowerCase())
		);
	}

	setFilteredTableData(result);

}, [tableData, sortingDirection, sortingByMonth, searchByNameQuery]);

useEffect(() => {
	fetchAdAccountsMetadata();
}, []);

const handleSortingBy = month => {
	if (month === sortingByMonth) {
		setSortingDirection(!sortingDirection);
	} else {
		setSortingByMonth(month);
		setSortingDirection(false);
	}
};

const stylesTableHead = month => ({
	color: sortingByMonth === month ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.6)',
	cursor: 'pointer'
});

const allMonthsYears = filteredTableData.length > 0
	? [...new Set(filteredTableData.flatMap(account => account.monthlySpend.map(spend => `${spend.month}-${spend.year}`)))]
		.sort((a, b) => {
			const [aMonth, aYear] = a.split('-');
			const [bMonth, bYear] = b.split('-');
			const aMonthIndex = monthOrder.indexOf(aMonth);
			const bMonthIndex = monthOrder.indexOf(bMonth);

			if (aYear !== bYear) {
				return bYear - aYear;
			} else {
				return bMonthIndex - aMonthIndex;
			}
		})
	: [];

return (
	<>
		<Title title="Ad Accounts Monthly Spend" />

		<TablePage>
			{loading ? (
				<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }} />
			) : error ? (
				<Alert severity="error">Error: {error}</Alert>
			) : (
			<TableContainer>
				<Box
				sx={{
					backgroundColor: '#f0f7fb',
					borderLeft: 'solid 4px #3498db',
					lineHeight: '18px',
					overflow: 'hidden',
					padding: '12px',
					textAlign: 'left',
					marginBottom: '12px'
				}}
				>
					<Typography color="text.secondary" variant="body2">
						Table displays monthly spend for each ad account.
					</Typography>
				</Box>

				<TextField
					sx={{ marginBottom: '20px' }}
					label="Search by account name"
					variant="outlined"
					fullWidth
					margin="normal"
					value={searchByNameQuery}
					onChange={(e) => setSearchByNameQuery(e.target.value)}
					/>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell rowSpan={2}>Account</TableCell>
							<TableCell colSpan={allMonthsYears.length}>Monthly Spends</TableCell>
						</TableRow>

						<TableRow>
							{allMonthsYears.map((monthYear, index) => {
								const [month, year] = monthYear.split('-');
								return (
								<TableCell
									key={index}
									style={stylesTableHead(month, year)}
									onClick={() => handleSortingBy(month)}
								>
									{month} {year}
									<ExpandMoreLessButton
									enabled={sortingByMonth === month}
									sortingDirection={sortingDirection}
									/>
								</TableCell>
								);
							})}
						</TableRow>
					</TableHead>

					<TableBody>
						{filteredTableData.map(account => (
						<TableRow key={account.adAccountId}>
							<TableCell>{account.adAccountName}</TableCell>
							{allMonthsYears.map((monthYear, index) => {
							const [month, year] = monthYear.split('-');
							const monthSpend = account.monthlySpend.find(spend => spend.month === month && spend.year === year);

							return (
								<TableCell key={index}>
								{monthSpend ? Number(monthSpend.totalSpend).toFixed(2) : 0} {account.currency === PLN_CURRENCY_CODE ? 'zł' : '$'}
								</TableCell>
							);
							})}
						</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			)}
		</TablePage>
	</>
	);
};
