import React, { useState, useEffect } from 'react';

import apiCallsService from '../services/apiCalls.service';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip, Typography } from '@mui/material';

const locationParameters = [
    { name: '{City}', example: 'New York' },
    { name: '{in City}', example: 'in New York' },
    { name: '{Country}', example: 'Spain' },
    { name: '{in Country}', example: 'in Spain' },
    { name: '{State}', example: 'California' },
    { name: '{in State}', example: 'in California' },
];

function LocationParameters() {
    const copyToClipboard = (e, text) => {
		e.preventDefault();

		navigator.clipboard.writeText(text);
    };

    return (
        <div >
            {locationParameters.map((param, index) => (
                <Tooltip title="Click to copy" key={index}>
                    <Typography
                        variant="body2"
                        style={{ cursor: 'pointer', display: 'inline-block', marginRight: '10px' }}
                        onClick={(e) => copyToClipboard(e, param.name)}
                    >
                        {param.name} <small>({param.example})</small>
                    </Typography>
                </Tooltip>
            ))}
        </div>
    );
}

export default function ModalKeywordsUpdating({open, setOpen, campaignId, campaignName, countryCode, campaignKeywords}) {
	const [keywordsAmount, setKeywordsAmount] = useState(5);
    const [keywords, setKeywords] = useState([]);

	const [duplicates, setDuplicates] = useState([]);
	const [emptyFields, setEmptyFields] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [errorBlock, setErrorBlock] = useState(false);

	console.log(campaignKeywords);

	useEffect(() => {
		const campaignKeywordsAmount = campaignKeywords.length >= 3 || campaignKeywords.length <= 10
			? campaignKeywords.length 
			: 5;

        setKeywordsAmount(campaignKeywordsAmount);
        setKeywords([...campaignKeywords]);
    }, [campaignKeywords]);

	const handleClose = () => {
		setOpen(false);

		
	};

	const handleKeywordsAmountChange = (event) => {
        const value = event.target.value;

        setKeywordsAmount(value);

		if (keywords.length > value) { 
			const elementQty = keywords.length - value

			const updatedKeywords = keywords.slice(0, -elementQty);

			setKeywords(updatedKeywords);

			console.log(keywords)
		}
    };

	const handleInputChange = (index, event) => {
        const values = [...keywords];

        values[index] = event.target.value;

        const duplicates = values.filter(item => values.indexOf(item) !== values.lastIndexOf(item) && item !== '');
		const hasEmptyFields = values.some(item => item === '');

        setKeywords(values);
		setDuplicates(duplicates);
		setEmptyFields(hasEmptyFields)
    };
	
	const handleSubmit = async () => {
		setIsLoading(true);

		const result = await apiCallsService.updateCampaignKeywords({
			campaignId,
			keywords,
			countryCode,
			keywordsAmount
		});

		if (result.error) {
			setErrorBlock(true);
			setIsLoading(false);

			return;
		}
		
		setIsLoading(false);
        handleClose();
    };

  return (
	<>
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			fullWidth={true}
			maxWidth={'md'}
		>
			<DialogTitle id="alert-dialog-title">
                Keywords for <b>{campaignName}</b> 
            </DialogTitle>

			{errorBlock && <MuiAlert severity="error" style={{marginBottom: '10px'}}>Failed to update campaign keywords</MuiAlert>}

			{isLoading 
				? (
					<div className='loading-box'>
						<CircularProgress />
					</div>
				)
				: <DialogContent>
					<Box mb={2} mt={1} width={'20%'}>
						<FormControl fullWidth>
							<InputLabel id="keyword-amount-label">Keyword amount</InputLabel>
							<Select
								labelId="keyword-amount-label"
								label="Keyword amount"
								value={keywordsAmount}
								onChange={handleKeywordsAmountChange}
							>
								{Array.from({ length: 8 }, (_, i) => i + 3).map((num) => (
									<MenuItem key={num} value={num}>
										{num}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box p={1} width={'100%'}>
						<LocationParameters/>
					</Box>
					
					{Array.from({ length: keywordsAmount }).map((_, index) => (
						<TextField
							key={index}
							label={`Keyword #${index + 1}`}
							value={keywords[index]}
							InputLabelProps={{
								shrink: true,
							  }}
							onChange={(event) => handleInputChange(index, event)}
							fullWidth
							margin="normal"
							error={duplicates.includes(keywords[index]) || keywords[index] === ''}
							helperText={keywords[index] === '' ? 'Field cannot be empty' : (duplicates.includes(keywords[index]) ? 'Duplicate keyword' : '')}
						/>
					))}
				</DialogContent>
			}

			<DialogActions>
				<Button onClick={handleSubmit} disabled={isLoading || duplicates.length > 0 || emptyFields}>Submit</Button>
				<Button onClick={handleClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	</>
  );
};
