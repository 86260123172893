import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Title } from '../components/Title';
import apiCallsService from '../services/apiCalls.service';
import TablePage from '../styledComponents/pages/TablePage';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';

import GTranslateIcon from '@mui/icons-material/GTranslate';

import { SeasonalityChart } from '../components/SeasonalityChart';
import ModalSeasonalityChartByKeyboard from '../components/ModalSeasonalityChartByKeyboard';
import ExpandMoreLessButton from '../components/ExpandMoreLessButton';
import TableOffersDataByCountry from '../components/TableOffersDataByCountry';

import { analysisConstants } from '../constants/analysis';
import { timeframeConstants } from '../constants/timeframes';

const AFFILATE_ID = 'tonic';

export const AnalyseTonicOffers = () => {
	const [searchParams] = useSearchParams();

	const countryCodeFromQuery = searchParams.get('country');
  	const offerIdFromQuery = searchParams.get('offer');
  	const periodIdFromQuery = searchParams.get('periodId');

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [offersMetadata, setOffersMetadata] = useState(null);
	const [countriesMetadata, setCountriesMetadata] = useState(null);
	const [accountsMetadata, setAccountsMetadata] = useState(null)


	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [selectedOffer, setSelectedOffer] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES);

	const [defaultFromDate, setDefaultFromDate] = useState(null);
	const [defaultToDate, setDefaultToDate] = useState(null);

	const [selectedFromDate, setSelectedFromDate] = useState(null);
	const [selectedToDate, setSelectedToDate] = useState(null);

	const [activeTab, setActiveTab] = useState("history-by-day");

	const [selectedTonicAccount, setSelectedTonicAccount] = useState(analysisConstants.ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS);

	const [filters, setFilters] = useState({clicks: '', avg: '' });

	const [sortingBy, setSortingBy] = useState('volume');
	const [sortingDirection, setSortingDirection] = useState(false);

	const [open, setOpen] = useState(false);
	const [keyword, setKeyword] = useState('');

	const [showDetailedStatistics, setShowDetailedStatistics] = useState(false);

	const [offerTimeframe, setOfferTimeframe] = useState(null);

	const fetchMetadata = async() => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getKeywordsPageMetadata(AFFILATE_ID);

			if (!response.countries || !response.offers) {
				return setError('Failed to fetch metadata');
			}

			setOffersMetadata(response.offers);
			setCountriesMetadata([analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES, ...response.countries]);
			setDefaultFromDate(timeframeConstants.DEFAULT_FROM_DATE);
			setDefaultToDate(timeframeConstants.DEFAULT_TO_DATE);
			setAccountsMetadata([analysisConstants.ACCOUNT_ID_TO_SELECT_ALL_ACCOUNTS, ...response.accounts]);

			const offerFromQuery = offerIdFromQuery && response.offers.find(offer => offer.id === offerIdFromQuery);
			const countryFromQuery = countryCodeFromQuery && response.countries.find(country => country.code === countryCodeFromQuery);

			setSelectedOffer(offerFromQuery || response.offers[0]);
			setSelectedCountry(countryFromQuery || analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES);

			setSelectedFromDate(timeframeConstants.OFFER_TIMEFRAMES_MAP[periodIdFromQuery]?.fromDate || timeframeConstants.DEFAULT_FROM_DATE);
			setSelectedToDate(timeframeConstants.OFFER_TIMEFRAMES_MAP[periodIdFromQuery]?.toDate || timeframeConstants.DEFAULT_TO_DATE);

			setOfferTimeframe(periodIdFromQuery || timeframeConstants.TIMEFRAME_TO_SELECT_ALL_PERIOD);
		} catch (err) {
			setError('Failed to fetch metadata');
		} finally {
			setLoading(false);
		}
	};

	const fetchKeywordsData = async () => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getKeywordsDataForOffer({
				affilateId: AFFILATE_ID,
				offer: selectedOffer.id,
				country: selectedCountry.code,
				fromDate: selectedFromDate || defaultFromDate,
				toDate: selectedToDate || defaultToDate,
				accountId: selectedTonicAccount.id
			});

			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);
			}
			else {
				response.table.forEach(record => {
					record.volume = record.avg * record.clicks;
				});

				setTableData(response.table || []);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSortingBy = attribute => {
		if (attribute === sortingBy) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
	};



	const handleClicksFilterChange = value => {
		setFilters({
			...filters,
			clicks: value
		});
	};


	const handleAvgFilterChange = value => {
		setFilters({
			...filters,
			avg: value
		});
	};

	const handleClickOpen = event => {
		event.preventDefault();

		setKeyword(event.target.textContent);
		setOpen(true);
	};

	const handleOfferDataByTimeframe = (timeframe, data) => {
		setOfferTimeframe(timeframe);
		setSelectedFromDate(data.fromDate);
		setSelectedToDate(data.toDate);
	}

	const handleSelectedFromDate = value => {
		setSelectedFromDate(dayjs(value).format('YYYY-MM-DD'));
		setOfferTimeframe(null);
	}

	const handleSelectedToDate = value => {
		setSelectedToDate(dayjs(value).format('YYYY-MM-DD'));
		setOfferTimeframe(null);
	}

	useEffect(() => {
		let result = [...tableData];

		if ((filters.clicks || filters.clicks === 0) && !isNaN(filters.clicks)) {
			const filterValue = +filters.clicks;
			result = result.filter(record => record.clicks > filterValue);
		}

		if ((filters.avg || filters.avg === 0) && !isNaN(filters.clicks)) {
			const filterValue = +filters.avg;
			result = result.filter(record => +record.avg > filterValue);
		}

		if (sortingBy) {
			if (sortingDirection) {
				result = result.sort((a, b) => a[sortingBy] - b[sortingBy])
			}
			else {
				result = result.sort((a, b) => b[sortingBy] - a[sortingBy])
			}
		}

		setFilteredTableData(result);

	}, [tableData, filters.clicks, filters.avg, sortingDirection, sortingBy]);


	useEffect(() => {
		if (!offersMetadata) {
			return;
		}

		fetchKeywordsData();
	}, [selectedOffer, selectedCountry, offersMetadata, selectedFromDate, selectedToDate, selectedTonicAccount]);

	useEffect(() => {
		fetchMetadata();
	}, []);


	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});


	return (
			<>
				<Title title="Tonic keywords" />

				{!offersMetadata && <Box display="flex" justifyContent="center" alignItems="center">
										<CircularProgress size="5rem" style={{ marginTop: '20%' }}/>
									</Box>}

				{offersMetadata && <TablePage>
					<FormControl sx={{ mb: '20px' }}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack direction="row" spacing={2} alignItems="center" mb={'20px'}>
								<DatePicker
									minDate={dayjs(defaultFromDate)}
									maxDate={dayjs(defaultToDate)}
									value={dayjs(selectedFromDate)}
									onChange={(newValue) => handleSelectedFromDate(newValue)}
									format="YYYY-MM-DD"
								/>
								<span>-</span>
								<DatePicker
									minDate={dayjs(defaultFromDate)}
									maxDate={dayjs(defaultToDate)}
									value={dayjs(selectedToDate)}
									onChange={(newValue) => handleSelectedToDate(newValue)}
									format="YYYY-MM-DD"
								/>
							</Stack>
						</LocalizationProvider>

						<ButtonGroup variant="outlined" sx={{justifyContent: 'end'}}>
							{Object.entries(timeframeConstants.OFFER_TIMEFRAMES_MAP).map(([timeframe, data], index) => (
								<Button onClick={() => handleOfferDataByTimeframe(timeframe, data)} variant={offerTimeframe === timeframe ? 'contained' : 'outlined'} key={index}>{data.title}</Button>
							))}
						</ButtonGroup>
					</FormControl>
					<FormControl fullWidth sx={{ mb: '20px' }}>
						<Autocomplete
								options={countriesMetadata}
								getOptionLabel={(option) => option.name}
								value={selectedCountry}
								onChange={(event, newValue) => {
									if(!newValue) {
										return;
									}

									setSelectedCountry(newValue);
								}}
								renderInput={(params) => <TextField {...params} label="Country" />}
						/>
					</FormControl>
					<FormControl fullWidth sx={{ mb: '20px' }}>
						<Autocomplete
								options={offersMetadata}
								getOptionLabel={(option) => option.name}
								value={selectedOffer}
								onChange={(event, newValue) => {
									if(!newValue) {
										return;
									}

									setSelectedOffer(newValue)
								}}
								renderInput={(params) => <TextField {...params} label="Offer" />}
						/>
					</FormControl>
					<FormControl fullWidth sx={{ mb: '20px' }}>
						<Autocomplete
								options={accountsMetadata}
								getOptionLabel={(option) => option.name}
								value={selectedTonicAccount}
								onChange={(event, newValue) => {
									if(!newValue) {
										return;
									}
									
									setSelectedTonicAccount(newValue)
								}}
								renderInput={(params) => <TextField {...params} label="Tonic account" />}
						/>
					</FormControl>

					{selectedOffer && selectedCountry && selectedTonicAccount && <Box sx={{ mb: '20px' }}>
						<Box><h4>Seasonality Charts</h4></Box>
						<Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="basic tabs example">
							<Tab value="history-by-day" label="History by day" />
							<Tab value="history-by-month" label="History by month" />
							<Tab value="hours-seasonality" label="Hours [PST -8]" />
							<Tab value="day-of-week-seasonality" label="Day of Week" />
							<Tab value="month-seasonality" label="Monthly" />
							<Tab value="country" label="by countries" />
						</Tabs>

						<Box style={{height: "250px"}}>
							<SeasonalityChart
								affilateId={AFFILATE_ID}
								periodType={activeTab}
								offerId={selectedOffer.id}
								country={selectedCountry.code === 'all' ? '' : selectedCountry.code}
								fromDate={selectedFromDate || defaultFromDate}
								toDate={selectedToDate || defaultToDate}
								accountId={selectedTonicAccount.id === 'all' ? '' : selectedTonicAccount.id}
							/>
						</Box>
					</Box>}

					<Box sx={{ mb: '10px' }}>
						<h4>Filters</h4>
					</Box>

					<Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: '20px' }}>
						<TextField
									label="AVG > x"
									variant="outlined"
									value={filters.avg}
									onChange={(e) => handleAvgFilterChange(e.target.value)}
							/>

						<TextField
								label="Clicks > x"
								variant="outlined"
								value={filters.clicks}
								onChange={(e) => handleClicksFilterChange(e.target.value)}
						/>

						<FormControlLabel control={
							<Checkbox
								checked={showDetailedStatistics}
								onChange={() => setShowDetailedStatistics(!showDetailedStatistics)} />} label='Show Geeky Columns'
						/>
					</Stack>


					{loading ? (
							<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
					) : error ? (
							<Alert severity="error">Error: {error}</Alert>
					) : selectedCountry.code === 'all' && selectedOffer && selectedTonicAccount ? (
							<TableOffersDataByCountry
								affilateId={AFFILATE_ID}
								offerId={selectedOffer.id}
								country={selectedCountry.code}
								fromDate={selectedFromDate || defaultFromDate}
								toDate={selectedToDate || defaultToDate}
								accountId={selectedTonicAccount.id === 'all' ? '' : selectedTonicAccount.id}
								countriesMetadata={countriesMetadata}
								showDetailedStatistics={showDetailedStatistics}
								filters={filters}
								offerTimeframe={offerTimeframe}
							/>
					) : ( 
							<TableContainer>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Keyword</TableCell>
											<TableCell style={stylesTableHead('avg')} onClick={() => handleSortingBy('avg')}>AVG <ExpandMoreLessButton enabled={sortingBy === 'avg'} sortingDirection={sortingDirection}/></TableCell>
											{showDetailedStatistics &&
												<>
													<TableCell style={stylesTableHead('percentile25')} onClick={() => handleSortingBy('percentile25')}>25th <ExpandMoreLessButton enabled={sortingBy === 'avg'} sortingDirection={sortingDirection}/></TableCell>
													<TableCell style={stylesTableHead('median')} onClick={() => handleSortingBy('median')}>Median <ExpandMoreLessButton enabled={sortingBy === 'median'} sortingDirection={sortingDirection}/></TableCell>
													<TableCell style={stylesTableHead('percentile75')} onClick={() => handleSortingBy('percentile75')}>75th <ExpandMoreLessButton enabled={sortingBy === 'percentile75'} sortingDirection={sortingDirection}/></TableCell>
												</>}
											<TableCell style={stylesTableHead('clicks')} onClick={() => handleSortingBy('clicks')}>Clicks <ExpandMoreLessButton enabled={sortingBy === 'clicks'} sortingDirection={sortingDirection}/></TableCell>
											<TableCell style={stylesTableHead('volume')} onClick={() => handleSortingBy('volume')}>Volume <ExpandMoreLessButton enabled={sortingBy === 'volume'} sortingDirection={sortingDirection}/></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{filteredTableData.slice(0, analysisConstants.MAX_TABLE_RECORDS_TO_RENDER).map((record, index) => (
												<TableRow key={index}>
													<TableCell>
														<Link href="#" onClick={(e) => handleClickOpen(e)}>
															{record.offer}
														</Link>
														<Link 
															href={`https://translate.google.com/?sl=auto&tl=en&text=${record.offer}`} 
															target='_blank'
															sx={{ marginLeft: '10px', verticalAlign: 'center' }}>
															<Tooltip title="Google Translate" placement="top">
																<GTranslateIcon sx={{ fontSize: 18 }}/>
															</Tooltip>
														</Link>
													</TableCell>
													<TableCell>{record.avg}$</TableCell>
													{showDetailedStatistics &&
														<>
															<TableCell>{record.percentile25}$</TableCell>
															<TableCell>{record.median}$</TableCell>
															<TableCell>{record.percentile75}$</TableCell>
														</>}
													<TableCell>{record.clicks}</TableCell>
													<TableCell>{(record.volume).toFixed(0)}$</TableCell>
												</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
					)}
				</TablePage>}
				{selectedOffer && selectedCountry && selectedTonicAccount && <ModalSeasonalityChartByKeyboard
					affilateId={AFFILATE_ID}
					open={open}
					setOpen={setOpen}
					keyword={keyword}
					offerId={selectedOffer?.id}
					country={selectedCountry.code === 'all' ? '' : selectedCountry.code}
					fromDate={selectedFromDate || defaultFromDate}
					toDate={selectedToDate || defaultToDate}
					accountId={selectedTonicAccount.id === 'all' ? '' : selectedTonicAccount.id}
				/>}
			</>
	);
};
