import React, { useEffect, useState } from 'react';

import apiCallsService from '../services/apiCalls.service';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const CURRENCY = 'PLN';

export const ExchangeRate = () => {
	const [isLoading, setIsLoading] = useState(false);

	const [exchangeRates, setExchangeRates] = useState({});
	const [exchangeRateError, setExchangeRateError] = useState(null);

	const fetchExchangeRateData = async () => {
		setIsLoading(true);

		const exchangeRateData = await apiCallsService.getExchangeRate(CURRENCY);

		if (exchangeRateData.error) {
			setExchangeRateError('Failed to get exchange rate');
			setIsLoading(false);

			return;
		}

		const savedExchangeRates = localStorage.getItem('exchangeRates');
		const parsedSavedExchangeRates = savedExchangeRates ? JSON.parse(savedExchangeRates) : null;

		if (JSON.stringify(parsedSavedExchangeRates) !== JSON.stringify(exchangeRateData.rates)) {
			localStorage.setItem('exchangeRates', JSON.stringify(exchangeRateData.rates));
		}
		
		setExchangeRates(exchangeRateData.rates);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchExchangeRateData();
	}, []);

	return (
		<Box style={{ 
			position: 'absolute', 
			bottom: '0',
			display: 'flex',
			flexDirection: 'column',
			padding: '0 0 35px 35px',
			color: 'rgb(95, 95, 95)'
		}}>
			<span>Exchange rate:</span>

			{isLoading ? (
				<CircularProgress size="1.25rem" sx={{ml: '40%', mt: '10px'}}/>
			) : exchangeRateError ? (
				<Alert severity="error">Error: {exchangeRateError}</Alert>
			) : ( 
				<List style={{padding: '0'}}>
					{Object.keys(exchangeRates).map((rate, index) => (
						<ListItem key={index} style={{paddingLeft: '10px'}}>
							{rate} - {exchangeRates[rate]}
						</ListItem>
					))}
				</List>
			)}
		</Box>
	);
};
