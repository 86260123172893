import React, { useEffect, useState } from 'react';

import { Title } from '../components/Title';
import ExpandMoreLessButton from '../components/ExpandMoreLessButton';
import apiCallsService from '../services/apiCalls.service';
import TablePage from '../styledComponents/pages/TablePage';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';

import GTranslateIcon from '@mui/icons-material/GTranslate';

import { analysisConstants } from '../constants/analysis';
import { timeframeConstants } from '../constants/timeframes';

export const AnalyseKeywordsList = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPage] = useState(100);
	const [totalCount, setTotalCount] = useState(0);

	const [affiliate, setAffiliate] = useState(analysisConstants.AFFILIATES[0])

	const [minNumberOfClicks, setMinNumberOfClicks] = useState(analysisConstants.DEFAULT_MIN_NUMBER_OF_CLICKS);
	const [minAVGRevenue, setMinAVGRevenue] = useState(analysisConstants.DEFAULT_MIN_AVG_REVENUE);

	const [isMinClicksInvalid, setIsMinClicksInvalid] = useState(false);

	const [tableData, setTableData] = useState([]);
	const [filteredTableData, setFilteredTableData] = useState([]);

	const [keywordFilter, setKeywordFilter] = useState('');
	const [country, setCountry] = useState(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES.code)

	const [searchTimeout, setSearchTimeout] = useState(null);

	const [sortingBy, setSortingBy] = useState('volume');
	const [sortingDirection, setSortingDirection] = useState(false);

	const [offerTimeframe, setOfferTimeframe] = useState('7d');

	const fetchOffersListData = async (keyword = keywordFilter) => {
		setLoading(true);
		setError('');

		try {
			const response = await apiCallsService.getAnalysedKeywordsList({
				affilateId: affiliate.id,
				period: offerTimeframe,
				country: country,
				keyword: keyword,
				minNumberOfClicks: minNumberOfClicks,
				minAVGRevenue: minAVGRevenue,
				page: currentPage,
				limit: rowsPerPage,
			});

			if (response.error) {
				setError(response.error);
				setTableData([]);
				setFilteredTableData([]);
			}
			else {
				response.data.forEach(record => {
					record.volume = record.averageRevenue * record.totalClicks;
				});

				setTableData(response.data || []);
				setTotalCount(response.totalCount);
			}
		} catch (err) {
			setError('Failed to fetch data');
			setTableData([]);
			setFilteredTableData([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let result = [...tableData];

		if (sortingBy) {
			if (sortingDirection) {
				result = result.sort((a, b) => a[sortingBy] - b[sortingBy])
			}
			else {
				result = result.sort((a, b) => b[sortingBy] - a[sortingBy])
			}
		}

		setFilteredTableData(result);

	}, [tableData, sortingDirection, sortingBy]);

	useEffect(() => {
		fetchOffersListData();
	}, [currentPage, affiliate, country, offerTimeframe, minNumberOfClicks, minAVGRevenue]);

	useEffect(() => {
		setCurrentPage(1); 
	}, [affiliate, country, offerTimeframe, minNumberOfClicks, minAVGRevenue]); 

	useEffect(() => {
		return () => {
			setLoading(false);
			clearTimeout(searchTimeout); 
		};
	}, [searchTimeout]);

	const handleSortingBy = attribute => {
		if (attribute === sortingBy) {
			return setSortingDirection(!sortingDirection);
		}

		setSortingBy(attribute);
		setSortingDirection(false);
	};

	const handleCountryChange = event => {
		const value = (event.target.value).toUpperCase();

		if (value === 'ALL' || value === '') {
			return setCountry(analysisConstants.COUNTRY_CODE_TO_SELECT_ALL_COUNTRIES.code);
		}

		if (value.length !== 2) {
			return;
		}

		setCountry(value);
	};

	const handleKeywordChange = async (event) => {
		const newKeyword = event.target.value;

		setKeywordFilter(newKeyword);
		clearTimeout(searchTimeout); 

		if (!newKeyword) { 
			setCurrentPage(1);
			await fetchOffersListData(newKeyword);
			return;
		}
	
		if (newKeyword.length >= 3) {
			const timeoutId = setTimeout(async () => {
				setLoading(true);
				
				setCurrentPage(1); 
				await fetchOffersListData(newKeyword);
			}, 1000);
	
			setSearchTimeout(timeoutId);

			return;
		};
	};

	const handleMinClicksChange = (event) => {
		const inputValue = event.target.value;
		const newMinClicks = parseInt(inputValue, 10);

		if (!isNaN(newMinClicks)) {
			if (newMinClicks >= 4) {
				setMinNumberOfClicks(newMinClicks);
				setIsMinClicksInvalid(false); 
			} else {
				setIsMinClicksInvalid(true); 
			}
		} else if (inputValue === '' || inputValue === '0') {
			setMinNumberOfClicks(0);
			setIsMinClicksInvalid(false);
		}
	};

	const getLinkToAnalyseOffer = (affilateId, offerId, country, periodId) => {
		const linkMap = {
			tonic: `/analyse-tonic-offers?`,
			crossroads: `/analyse-crossroads-offers?`
		}

		const queryParam = `country=${country}&offer=${offerId}&periodId=${periodId || 'All'}`;

		return linkMap[affilateId] + queryParam;
	}

	const stylesTableHead = attribute => ({
		color: sortingBy === attribute ? 'rgba(0, 0, 0, 1)': 'rgba(0, 0, 0, 0.6)',
		cursor: 'pointer'
	});

	return (
		<>
			<Title title="Keywords list" />
			<TablePage>
				<FormControl sx={{ mb: '20px' }}>
					<ButtonGroup variant="outlined" sx={{justifyContent: 'end'}}>
						{Object.entries(timeframeConstants.OFFER_TIMEFRAMES_MAP).map(([timeframe, data], index) => {
							if (timeframe === 'All') {
								return <></>;
							}

							return <Button onClick={() => setOfferTimeframe(timeframe)} variant={offerTimeframe === timeframe ? 'contained' : 'outlined'} key={index}>{data.title}</Button>
						})}
					</ButtonGroup>
				</FormControl>

				<FormControl fullWidth sx={{ mb: '20px' }}>
					<Autocomplete
						options={analysisConstants.AFFILIATES}
						getOptionLabel={(option) => option.name}
						value={affiliate}
						renderInput={(params) => <TextField {...params} label="Affiliate" />}
						onChange={(event, newValue) => setAffiliate(newValue)}
					/>
				</FormControl>

				<Box sx={{ mb: '10px' }}>
					<h4>Filters</h4>
				</Box>

				<Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mb: '20px' }}>
					<TextField
						onChange={handleKeywordChange}
						value={keywordFilter}
						variant="outlined"
						label="Keyword"
						size="small"
						sx={{ mb: 1, mr: 1, textAlign: 'start', width: '70%' }}>
					</TextField>

					<TextField
						onChange={handleCountryChange}
						defaultValue={country.toUpperCase()}
						variant="outlined"
						label="Country"
						size="small"
						sx={{mb: 1, mr: 1, width: '15%' }}>	
					</TextField>

					<TextField
						onChange={event => setMinAVGRevenue(event.target.value * 100)}
						type="number"
						defaultValue={minAVGRevenue || '0.00'}
						variant="outlined"
						label="AVG revenue > x"
						size="small"
						sx={{mb: 1, mr: 1, width: '15%' }}>	
					</TextField>

					<TextField
						onChange={handleMinClicksChange}
						type="number"
						defaultValue={minNumberOfClicks}
						variant="outlined"
						label="Clicks > x"
						size="small"
						sx={{mb: 1, mr: 1, width: '15%'}}
						inputProps={{ min: 4 }}
						error={isMinClicksInvalid} 
      					helperText={isMinClicksInvalid && 'Minimum value is 4'}>
					</TextField>
				</Stack>

				{loading ? (
						<CircularProgress size="4rem" sx={{ mr: '50%', mt: '10%' }}/>
				) : error ? (
						<Alert severity="error">Error: {error}</Alert>
				) : filteredTableData.length > 0 ? ( 
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Keyword</TableCell>
									<TableCell>Country</TableCell>
									<TableCell style={stylesTableHead('avg')} onClick={() => handleSortingBy('averageRevenue')}>AVG <ExpandMoreLessButton enabled={sortingBy === 'averageRevenue'} sortingDirection={sortingDirection}/></TableCell>
									<TableCell style={stylesTableHead('clicks')} onClick={() => handleSortingBy('totalClicks')}>Clicks <ExpandMoreLessButton enabled={sortingBy === 'totalClicks'} sortingDirection={sortingDirection}/></TableCell>
									<TableCell style={stylesTableHead('volume')} onClick={() => handleSortingBy('volume')}>Volume <ExpandMoreLessButton enabled={sortingBy === 'volume'} sortingDirection={sortingDirection}/></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredTableData.slice(0, analysisConstants.MAX_TABLE_RECORDS_TO_RENDER).map((record, index) => (
										<TableRow key={index}>
											<TableCell>
												<Link href={getLinkToAnalyseOffer(affiliate.id, record.offerId, record.country, offerTimeframe)} target="_blank">
													{record.keyword}
												</Link>
												<Link 
													href={`https://translate.google.com/?sl=auto&tl=en&text=${record.keyword}`} 
													target='_blank'
													sx={{ marginLeft: '10px', verticalAlign: 'center' }}>
													<Tooltip title="Google Translate" placement="top">
														<GTranslateIcon sx={{ fontSize: 18 }}/>
													</Tooltip>
												</Link>
											</TableCell>
											<TableCell>{record.country}</TableCell>
											<TableCell>{record.averageRevenue}$</TableCell>
											<TableCell>{record.totalClicks}</TableCell>
											<TableCell>{(record.volume).toFixed(0)}$</TableCell>
										</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer> 
				) : (
					<Box sx={{ mb: '10px', textAlign: 'center' }}>
						<span style={{ color: 'gray' }}> 
							No data found for keyword:
						</span>
						{` ${keywordFilter}`}
					</Box>
				)}
				<TablePagination
					component="div"
					count={totalCount}
					page={currentPage - 1} 
					onPageChange={(event, newPage) => setCurrentPage(newPage + 1)} 
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[rowsPerPage]}
				/>
			</TablePage>
		</>
	);
};
